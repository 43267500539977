import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import Button from "../../../components/Button/Button"

import * as styles from "./CTA.module.css"

const CTA = () => {
  const { prismicContact } = useStaticQuery(graphql`
    query {
      prismicContact {
        data {
          cta_title {
            text
          }
          cta_text {
            html
          }
          cta_image {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  `)

  const ctaData = prismicContact.data
  const ctaImage = getImage(ctaData.cta_image)

  return (
    <>
      <Container>
        <div className="flex flex-col md:flex-row">
          <div className="flex-auto w-full md:w-[45%]">
            <GatsbyImage image={ctaImage} alt={ctaData.cta_title.text} />
          </div>
          <div className="flex-auto w-full md:w-[55%] md:pl-3 lg:pl-5 md:pt-0 pt-10">
            <div className="text-center">
              <Title>{ctaData.cta_title.text}</Title>
            </div>

            <div className="pt-3 text-center md:pt-5">
              <div
                className={styles.texts}
                dangerouslySetInnerHTML={{ __html: ctaData.cta_text.html }}
              />
            </div>
            <div className="flex justify-center pt-6 md:pt-10">
              <Button arrowRight linkTo="/booking">
                Reserve Now
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default CTA
