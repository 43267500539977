import React from "react"
import { graphql } from "gatsby"

import SEO from "../components/SEO/SEO"

import Hero from "../sections/contact/Hero/Hero"
import Location from "../sections/common/Location/Location"
import CTA from "../sections/contact/CTA/CTA"
import ContactForm from "../sections/contact/ContactForm/ContactForm"

const ContactPage = ({ data }) => {
  if (!data) return null

  const pageData = data.prismicContact.data

  return (
    <>
      <SEO
        title={pageData.meta_title.text}
        description={pageData.meta_description.text}
      />

      <section>
        <Hero />
      </section>

      <section>
        <CTA />
      </section>

      <section>
        <ContactForm />
      </section>

      <section className="pb-2 md:pb-3 lg:pb-5">
        <Location />
      </section>
    </>
  )
}

export default ContactPage

export const query = graphql`
  query {
    prismicContact {
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
      }
    }
  }
`
