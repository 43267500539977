import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Container from "../../../components/Container/Container"
import Title from "../../../components/Title/Title"
import { Phone, Compass, Envelop } from "../../../components/Icons"

const Hero = () => {
  const { prismicContact } = useStaticQuery(graphql`
    query {
      prismicContact {
        data {
          contact_pre_title {
            text
          }
          contact_title_1 {
            text
          }
          contact_title_2 {
            text
          }
          contact_intro {
            html
          }
          contact_phone {
            text
          }
          contact_address {
            html
          }
          contact_email {
            text
          }
        }
      }
    }
  `)

  const contactData = prismicContact.data

  return (
    <>
      <Container>
        <div className="flex flex-col items-center text-center">
          <div>
            <Title preTitle>{contactData.contact_pre_title.text}</Title>
          </div>
          <h1 className="flex flex-col items-center sm:flex-row">
            <div className="sm:mr-[-16px]">
              <Title>{contactData.contact_title_1.text}</Title>
            </div>
            <StaticImage
              src="../../../assets/images/and-bg-symbol.png"
              alt="And bg"
              placeholder="none"
              className="hidden sm:block mt-[-90px]"
            />
            <div className="sm:ml-[-24px]">
              <Title>{contactData.contact_title_2.text}</Title>
            </div>
          </h1>
          <div className="pt-3 sm:pt-7 max-w-[630px]">
            <div
              dangerouslySetInnerHTML={{
                __html: contactData.contact_intro.html,
              }}
            />
          </div>
        </div>
      </Container>
      <Container>
        <div className="flex flex-col lg:flex-row">
          <div className="flex flex-auto w-full lg:w-1/3">
            <div className="text-[64px] stroke-[2.3px] mr-5 opacity-60">
              <Phone />
            </div>
            <div>
              <Title smallTitle>Phone</Title>
              <div className="text-base lg:text-sm lg:leading-6 lg:tracking-[5px] text-primary">
                {contactData.contact_phone.text}
              </div>
            </div>
          </div>
          <div className="flex flex-auto w-full pt-10 lg:w-1/3 lg:pt-0 ">
            <div className="text-[64px] stroke-[2.3px] mr-5 opacity-60">
              <Compass />
            </div>
            <div>
              <Title smallTitle>Address</Title>
              <div className="text-base lg:text-sm lg:leading-6 lg:tracking-[5px] text-primary">
                <div
                  dangerouslySetInnerHTML={{
                    __html: contactData.contact_address.html,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-auto w-full pt-10 lg:w-1/3 lg:pt-0">
            <div className="text-[64px] stroke-[2.3px] mr-5 opacity-60">
              <Envelop />
            </div>
            <div>
              <Title smallTitle>Email</Title>
              <div className="text-base lg:text-sm lg:leading-6 lg:tracking-[5px] text-primary">
                {contactData.contact_email.text}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Hero
