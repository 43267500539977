import React from "react"

import Title from "../../../components/Title/Title"
import Container from "../../../components/Container/Container"
import Button from "../../../components/Button/Button"

const ContactForm = () => {
  return (
    <div className="bg-[#ebebeb] bg-opacity-90">
      <Container>
        <Title smallTitle>Send us a message:</Title>
        <form
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          {/* You still need to add the hidden input with the form name to your JSX form */}
          <input type="hidden" name="form-name" value="contact" />

          <div className="grid grid-cols-1 gap-4 mb-4 pt-7">
            <label className="block">
              <input
                type="text"
                className="block w-full mt-1 bg-white border-transparent focus:border-white focus:ring-0 py-[14px] px-[24px] text-sm leading-[21px] lg:text-base lg:leading-[28.8px] placeholder-body placeholder-opacity-70"
                placeholder="Full Name:"
                name="name"
                required
              />
            </label>
          </div>

          <div className="grid gap-4 mb-4 lg:grid-cols-2">
            <label className="block">
              <input
                type="email"
                className="block w-full mt-1 bg-white border-transparent focus:border-white focus:ring-0 py-[14px] px-[24px] text-sm leading-[21px] lg:text-base lg:leading-[28.8px] placeholder-body placeholder-opacity-70"
                placeholder="Email Address:"
                name="email"
                required
              />
            </label>
            <label className="block">
              <input
                type="tel"
                className="block w-full mt-1 bg-white border-transparent focus:border-white focus:ring-0 py-[14px] px-[24px] text-sm leading-[21px] lg:text-base lg:leading-[28.8px] placeholder-body placeholder-opacity-70"
                placeholder="Phone Number:"
                name="phone"
                required
              />
            </label>
          </div>
          <div className="grid grid-cols-1 gap-4">
            <label className="block">
              <input
                type="text"
                className="block w-full mt-1 bg-white border-transparent focus:border-white focus:ring-0 py-[14px] px-[24px] text-sm leading-[21px] lg:text-base lg:leading-[28.8px] placeholder-body placeholder-opacity-70"
                placeholder="Subject:"
                name="subject"
                required
              />
            </label>

            <label className="block">
              <textarea
                className="block w-full mt-1 bg-white border-transparent focus:border-white focus:ring-0 py-[14px] px-[24px] text-sm leading-[21px] lg:text-base lg:leading-[28.8px] placeholder-body placeholder-opacity-70"
                rows="4"
                placeholder="Message"
                name="message"
                required
              ></textarea>
            </label>
          </div>
          <div className="flex justify-center pt-7">
            <Button arrowRight btnType="submit">
              Submit
            </Button>
          </div>
        </form>
      </Container>
    </div>
  )
}

export default ContactForm
